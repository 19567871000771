/* eslint-disable no-use-before-define */
import Axios from 'axios';

Axios.interceptors.response.use(
  res => res,
  err => {
    // console.log('request passed: ', err.response);
    if (err.response.status === 401) {
      handleLogout();
    }
    return err;
  },
);

const handleLogout = () => {
  const language = getCookie('language');
  document.cookie.split(';').forEach(cookies => {
    document.cookie = cookies
      .replace(/^ +/, '')
      .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
  });

  document.cookie = `language=${!!language ? language : 'ind'}; max-age=${30 * 24 * 60 * 60}; path=/`;
  setTimeout(() => location.reload(), 1000);
};

const getCookie = c_name => {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(`${c_name}=`);
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(';', c_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return '';
};
